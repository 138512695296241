@import 'compass';
@import 'compass/support';

@import 'setting';
@import 'mixin';

/* ======================================================================
 p_404
====================================================================== */
@media screen and (max-width: 768px) {
  .p_404 .underlayer_title_container .underlayer_title_box .underlayer_title_img {
    height: auto;
  }
}
.p_404 {
  .underlayer_title_container {
    position: relative;
    z-index: 1;

    .underlayer_title_box {
      height: 350px;

      .underlayer_title {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        font-size: 32px;
        font-weight: 400;
        font-family: $min_font;
        line-height: 1.4;
        letter-spacing: 0.09em;
        text-shadow: 0px 0px 3px #ffffff;
      }

      .underlayer_title_img {
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: top;
        object-position: top;
        width: 100%;
        height: 350px;
      }
    }

    .breadcrumbs {
      a {
        margin-right: 8px;
        font-size: 12px;
        border-bottom: 1px solid;
        letter-spacing: 0;
      }

      .breadcrumb_last {
        margin-left: 8px;
        font-size: 12px;
        letter-spacing: 0.01em;
      }
    }

    @include mq_min_769 {
      .breadcrumbs {
        position: absolute;
        z-index: 2;
        width: 100%;
        max-width: 1080px;
        right: 0;
        bottom: 16px;
        left: 0;
        margin: auto;
      }
    }

    @include mq_max_768 {
      .underlayer_title_box {
        height: 44vw;

        .underlayer_title_inner {
          position: relative;
          z-index: 1;
        }

        .underlayer_title {
          font-size: 6.4vw;
          writing-mode: unset;
          -webkit-text-orientation: unset;
          text-orientation: unset;
        }
      }

      .breadcrumbs {
        margin-top: 4vw;
        padding: 0 5.333vw;

        a {
          font-size: 3.2vw;
        }

        .breadcrumb_last {
          font-size: 3.2vw;
        }
      }
    }
  }

  .sitemap {
    margin-top: 100px;

    .sitemap_contents {
      display: block;
      position: relative;
      z-index: 1;
      padding-top: 25px;
      border-top: 1px solid #E3E3E3;

      &:not(:first-child) {
        margin-top: 80px;
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        z-index: 2;
        top: -1px;
        left: 0;
        width: 100px;
        height: 0;
        border-bottom: 1px solid #222;
      }
    }

    .sitemap_title {
      font-size: 25px;
    }

    .sitemap_list {
      display: block;

      .sitemap_item {
        margin-top: 30px;

        &:not(:last-child) {
          margin-right: 40px;
        }
      }

      .sitemap_link {
        display: block;
        position: relative;
        z-index: 1;
        padding-right: 45px;
        font-size: 16px;
        font-weight: 400;
        font-family: $min_font;
        text-align: center;
        transition: all .2s linear;

        &::after {
          content: "";
          display: block;
          position: absolute;
          z-index: 2;
          top: 0;
          right: 11px;
          bottom: 0;
          width: 29px;
          height: 6px;
          margin: auto;
          background: url(../img/common/icon_btn_arrow.svg) no-repeat;
          transition: all .2s linear;
        }
      }
    }

    @include mq_min_769 {
      .sitemap_list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: left;

        .sitemap_link {
          &:hover {
            &::after {
              right: 0;
            }
          }
        }
      }
    }

    @include mq_max_768 {
      margin-top: 22.833vw;

      .sitemap_contents {
        padding-top: 4.033vw;

        &:not(:first-child) {
          margin-top: 16.267vw;
        }

        &::after {
          width: 26.667vw;
        }
      }

      .sitemap_title {
        font-size: 5.333vw;
        line-height: 1.6;
        letter-spacing: 0.04em;
      }

      .sitemap_list {
        .sitemap_item {
          margin-top: 7vw;

          &:not(:last-child) {
            margin-right: 0;
          }
        }

        .sitemap_link {
          display: inline-block;
          width: auto;
          padding-right: 10.667vw;
          font-size: 3.733vw;

          &::after {
            right: 0;
            width: 7.733vw;
            height: 1.6vw;
            background: url(../img/common/icon_btn_arrow.svg) no-repeat;
          }
        }
      }
    }
  }
}